(async () => {
  await import("./main")
})()

async function loadLazyload() {
  if (document.querySelectorAll("[data-src], [data-bg]").length) {
    const { lazyLoading } = await import("./lazyload/lazyload-init")
    window.lazyloading = lazyLoading // add lazyloading to global window object
  }
}
loadLazyload()

async function loadFancybox() {
  if (document.querySelectorAll("[data-fancybox]").length) {
    await import("./fancybox/fancybox")
  }
}
loadFancybox()

async function loadFormValidation() {
  if (document.querySelectorAll('[data-validate="jquery"]').length) {
    await import("./form-validation");
  }
}
loadFormValidation()

async function loadMainMap() {
  if (document.getElementById('map') !== null) {
    await import("./main-map");
  }
}
loadMainMap()

async function loadMainInlineMap() {
  if (document.getElementById('map_inline') !== null) {
    await import("./main-inline-map");
  }
}
loadMainInlineMap()

async function loadAutoComplete() {
  if (document.getElementById('test') !== null) {
    await import("./autocomplete");
  }
}
loadAutoComplete()

async function loadCharts() {
  if (document.querySelectorAll(".i-broker-list-chart, .i-broker-chart, .i-review-chart").length) {
    await import("./chart");
  }
}
loadCharts()

async function loadSplide() {
  // if (document.querySelectorAll(".s-posts-slide, .s-agents-slider").length) {
  if (document.querySelectorAll(".splide").length) {
    await import("./splide");
  }
}
loadSplide()

async function loadChoices() {
  if (document.querySelectorAll(".select.i-pagination__select, select.i-pagination__select, .dropdown__wrapper").length) {
    await import("./choices");
  }
}
loadChoices()

async function loadSwiper() {
  if (document.querySelectorAll(".s-broker-review-slide, .s-dev-project-slide, .s-estates-slide, .s-re-agency-detail-branches, .swiper").length) {
    await import("./swiper");
  }
}
loadSwiper()

async function loadMainHeader() {
  if (document.querySelector('.main-header') !== null) {
    await import("./main-header");
  }
}
loadMainHeader()
